.col-1 {
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.col-1-row-1 {
  height: 20%;
  padding-bottom: 15px;
}
.article-icon {
  cursor: pointer;
  font-size: 20px;
}
.col-1-col-2 {
  margin-top: -10px;
  margin-left: -50px;
}
.header-text {
  color: #111;
  font-size: 30px;
  font-weight: 700;
}
.col-1-row-2 {
  height: 20%;
}
.search-icon {
  margin-top: 10px;
}
.col-1-col-3 {
  height: 280px;
  margin-top: 75px;
}
.input-pick {
  display: none !important;
}
.border-typo-1 {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.input-typo-1 {
  width: 90% !important;
  background-color: #f4f6f8 !important;
  margin-bottom: 20px !important;
}
.staff-input-typo-1 {
  width: 90% !important;
  background-color: #f4f6f8 !important;
}
.btn-typo-1 {
  background-color: #fe8781 !important;
  font-size: 15px !important;
  color: white !important;
  font-weight: bold !important;
  width: 90% !important;
  height: 40px !important;
  margin-top: 1px !important;
}
.btn-disabled-typo-1 {
  background-color: #a3a3a3 !important;
  font-size: 15px !important;
  color: white !important;
  font-weight: bold !important;
  width: 90% !important;
  height: 40px !important;
  margin-top: 1px !important;
}
.article-img-cover {
  width: 100%;
  height: 250px;
  resize: cover;
  margin-top: -22px;
}
.btn-margin {
  margin-top: 10px;
  margin-left: 10px;
}
.btn-span {
  margin-left: -5px;
}
.col-1-col-4 {
  height: 300px;
  justify-content: center;
}
.input-margin {
  margin-bottom: 20px;
}
.row-1 {
  padding-top: 5%;
}
.select-1 {
  width: 90% !important;
  font-weight: bold;
  font-size: 13px;
  color: black;
}
.row-3 {
  margin: 0 auto;
  margin-top: 10px;
  width: 90%;
}
.row-3-col-1 {
  margin-top: 150px;
}
.row-3-col-2 {
  margin-top: 50px;
}
.active-article {
  width: 300px;
  height: 313px;
  cursor: pointer;
  margin-top: 0px;
  opacity: 1 !important;
}
.deactive-article {
  width: 200px;
  height: 220px;
  cursor: pointer;
  margin-top: 30px;
  opacity: 0.5 !important;
}
.card-img {
  height: 220px;
  width: 200px;
}
.delete-icon {
  font-size: 20px !important;
  color: #fe8781 !important;
}
.edit-icon {
  font-size: 20px !important;
  color: #404040 !important;
}
.card-avatar {
  border-radius: 50% !important;
  width: 30px !important;
  height: 30px !important;
}
.article-alert {
  color: #fe8781;
  font-size: 25px;
  font-weight: bold;
  margin-left: 300px;
}
.active-col {
  margin-left: 2px;
  margin-right: 100px;
}
.auth-row-1 {
  text-align: center;
  margin-left: -220px;
  background-attachment: #404040;
}
.auth-image {
  width: 138%;
  height: 100vh;
  margin-left: -16px;
}
.auth-col-1 {
  padding-top: 20%;
  margin-left: 220px;
}
.auth-col-text {
  font-size: 25px;
  font-weight: bold;
  padding-left: 27%;
}
.auth-col-text-2 {
  font-size: 2rem;
  font-weight: bold;
  padding-left: 25%;
}
.auth-col-text-3 {
  font-size: 2rem;
  font-weight: bold;
  padding-left: 20%;
}
.auth-row-2 {
  justify-content: center;
  padding-top: 10px;
  padding-left: 1%;
}
.auth-row-2-text {
  font-size: 15px;
}
.auth-row-3 {
  margin-top: 60px;
  justify-content: center;
}
.auth-input {
  width: 100% !important;
  background-color: #f4f6f8 !important;
}
.mail-icon {
  font-size: 18px;
}
.staff-row-1 {
  padding-top: 5%;
  padding-left: 35px;
}
.staff-row-1-col-1 {
  padding-right: 20px;
}
.staff-img {
  cursor: pointer;
  height: 240px;
  width: 90%;
  resize: cover;
  margin-top: -5px;
  margin-left: -25px;
}
.staff-col-2 {
  padding-top: 10px;
}
.staff-row-2 {
  margin-bottom: -4px;
}
.staff-row-2-col-1 {
  margin-left: -15px;
}
.row-1-col-1-text {
  font-size: 25px;
  font-weight: bold;
}
.col-2-search-icon {
  font-size: 20px;
  font-weight: bold;
}
.row-1-col-2-header {
  margin-top: 5px;
  margin-left: -100px;
}
.row-1-col-3 {
  margin-left: -75px;
}
.row-1-col-4 {
  margin-top: 5px;
  margin-left: 100px;
}
.plus-icon {
  margin-top: 10px;
  cursor: pointer;
  font-size: 20px;
}
.right-icon {
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.row-1-col-5 {
  margin-top: -13px;
}
.show-staff-row-1 {
  height: 100%;
}
.table-img {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}
.gender-text {
  color: #111;
  font-size: 12px;
  font-weight: bold;
  border-radius: 30px;
  padding-top: 3px;
  background-color: #f4f6f8;
}
.active-table-btn {
  font-size: 13px !important;
  width: 90% !important;
  background-color: #404040 !important;
  color: white !important;
}
.deactive-table-btn {
  font-size: 13px !important;
  width: 90% !important;
  background-color: #fe8781 !important;
  color: white !important;
}
.discon-icon {
  color: #fe8781;
}
.date-text {
  font-weight: bold;
  color: black;
  font-size: 10px;
}
.tooltip-text {
  font-weight: bold;
  color: #7b7c7d;
  font-size: 10px;
}
.main-col-2 {
  display: inline-block;
  margin-top: 100px;
}
.main-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.main-row-2 {
  justify-content: center;
  padding-bottom: 10px;
}
.wlc-text {
  font-weight: bold;
  font-size: 20px;
}
.overview-text {
  font-size: 15px;
}
.center-row {
  justify-content: center;
}
.data-text {
  font-weight: bold;
  font-size: 20px;
}
.main-last-row {
  padding-top: 20px;
}
.main-last-col-1 {
  justify-content: center;
  padding-left: 40px;
}
.popover-header-text {
  font-weight: bold;
  color: black;
  font-size: 12px;
}
.header-date-text {
  font-weight: bold;
  font-size: 9px;
  color: #7b7c7d;
}
.left-bar {
  font-weight: bold;
  color: black;
  font-size: 10px;
}
.right-bar {
  font-weight: bold;
  color: #fe8781;
  font-size: 10px;
}
.row-width {
  width: 150px;
}
.row-padding {
  padding-top: 10px;
}
.col-align {
  align-items: center;
}
.chart-col {
  height: 100;
  cursor: pointer;
}
.month-text {
  transform: rotate(-90deg);
  margin-left: -10px;
  margin-top: 10px;
  cursor: pointer;
}
.prof-col-1 {
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.prof-row-1 {
  height: 20%;
}
.back-icon {
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.prof-header {
  margin-top: -10px;
}
.prof-header-text {
  color: #111;
  font-size: 25px;
  font-weight: 700;
}
.prof-plus-icon {
  margin-top: 10px;
  cursor: pointer;
  font-size: 20px;
}
.add-prof-row-1 {
  padding-top: 5%;
  padding-left: 50px;
}
.toast-cont {
  width: 70%;
}
.prof-row-2 {
  margin-bottom: -4px;
}
.multi-select {
  width: 100% !important;
  margin-top: 8px;
}
.prof-last-col {
  padding-top: 10px;
  padding-left: 10px;
}
.prof-last-row-1 {
  padding-bottom: 38px;
}
.prof-down-icon {
  cursor: pointer;
  color: #7b7c7d;
}
.prof-up-icon {
  cursor: pointer;
  color: black;
}
.age-col {
  margin-left: -17px;
}
.active-text {
  color: black;
  font-weight: bold;
}
.deactive-text {
  color: #7b7c7d;
  font-weight: bold;
}
.active-row {
  display: block !important;
  margin-top: -30px;
}
.deactive-row {
  display: none !important;
  margin-top: -30px;
  margin-bottom: -12px;
  margin-left: -12px;
}
.table-text {
  font-weight: bold;
}
.table-icon {
  font-size: 20px;
}
.table-badge {
  color: #fe8781;
  font-size: 18px;
  font-weight: bold;
}
.drawer-row-1 {
  justify-content: center;
  padding-top: 30px;
  padding-left: 100px;
}
.drawer-row-2 {
  padding: 14px;
  display: inline-block;
  background-color: #348275;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  width: 50px;
  height: 50px;
}
.drawer-row-3 {
  justify-content: center;
  padding-top: 30px;
  padding-right: 80px;
}
.drawer-row-3-text {
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
}
.drawer-last-row {
  padding-right: 100px;
}
.drawer-last-row-text {
  font-size: 10px;
  font-weight: bold;
}
.msg-row-1 {
  margin-top: 15%;
}
.msg-row-1-text {
  font-size: 20px;
  padding-left: 17%;
}
.grp-block {
  overflow-y: auto;
  height: 40%;
  width: 100%;
}
.grp-row-1 {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}
.open-icon {
  margin-top: 10px;
  cursor: pointer;
}
.btn-margin {
  margin-top: 10px !important;
}
.drawer-body-div {
  height: 50px;
  margin: 10px;
  width: 100px;
  background-color: #234253;
  border-radius: 15px;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.drawer-icon {
  margin: 10px;
  font-size: 25px;
}
.input-cont {
  border-radius: 10px;
}
