@font-face {
  font-family: globalFont;
  src: url(./assets/fonts/KumbhSans-Bold.ttf);
}
body {
  margin: 0;
  font-family: globalFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chat {
  height: 38rem;
  background-color: #f4f6f8;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}
* {
  box-sizing: border-box;
}

.input-container {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.icon {
  padding: 10px;
  background: dodgerblue;
  color: white;
  min-width: 50px;
  text-align: center;
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

/* Set a style for the submit button */
.btn {
  background-color: dodgerblue;
  color: white;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.btn:hover {
  opacity: 1;
}

.App {
  text-align: center;
  background-color: aqua;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-nav {
  border-bottom: 0;
}

.Toastify__toast {
  border-radius: 10px !important;
}

.transformText {
  transform-origin: 0 0;
  transform: rotate(90deg);
}
#form-input-title {
  background-color: rgb(227, 231, 233);
  color: black !important;
}
#timeSelect {
  background-color: rgb(227, 231, 233);
  color: black;
  margin-left: 10;
}
#audiSelect {
  background-color: rgb(227, 231, 233);
  color: black;
}
.icon {
  background: white !important;
  color: black !important;
}
.dropdown_icon {
  background: aqua !important;
  color: black !important;
  min-width: 10px;

  font-size: 5px;
  direction: ltr;
}

.chat {
  background-color: #fff;
}
.react-bell-chat__chat-bubble {
  background-color: #fe8781 !important;
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background-color: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}
.main-div-style {
  height: 57rem !important;
  padding-right: 10% !important;
  padding-left: 10% !important;
}
